import { useEffect, useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import useAxios from "../../utils/useAxios";
import PermissionContext from "../../context/PermissionContext";
import { resObj, paginationVal } from "../../utils/constants";
import DrawerContext from "../../context/DrawerContext";
import AutoLogout from "../../utils/useAutoLogout";

import Pagination from '@mui/material/Pagination';

import Loader from "../../components/layout/Loader";
import CustomTable from "../../components/layout/CustomTable";
import NoData from "../../components/layout/NoData";
import QualityHeader from "../../components/qualitycontrol/QualityHeader";
import SuccessAlert from "../../components/layout/SuccessAlert";
import QualityMultiGraphModal from "../../components/qualitycontrol/QualityMultiGraphModal";
import ErrorModal from "../../components/layout/ErrorModal";
import SideDrawer from "../../components/layout/SideDrawer";
import QualityFilterModal from "../../components/qualitycontrol/QualityFilterModal";

const QualityControlPage = () => {
  const api = useAxios();
  const navigate = useNavigate();
  
  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission([
      'Quality-Quality Control Specialist', 
      'Quality-Quality Control View Data'
    ]);
  }, [permisionsList]);

  const graphObj = {
    qualityControl: '',
    graphList: [],
  };
  const pageData = useRef({...paginationVal});
  const [apiRes, setApiRes] = useState(resObj);
  const [plateList, setPlateList] = useState([]);
  const [controlList, setControlList] = useState([]);
  const [graphIsOpen, setGraphIsOpen] = useState(false);
  const [graphValues, setGraphValues] = useState(graphObj);
  const [graphTempValues, setGraphTempValues] = useState(graphObj);
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [selectedPlateList, setSelectedPlateList] = useState([]);
  const [unverifiedPlateList, setUnverifiedPlateList] = useState([]);
  const [hoverId, setHoverId] = useState(-1);
  const [showGraphMetric, setShowGraphMetric] = useState('');
  const [latestMetricName, setLatestMetricName] = useState('');
  const [graphFilterList, setGraphFilterList] = useState([]);

  const tableHeading = [
    'SI No', 'Plate Name', 'Control Metrics', 'Message', 'Last Modified'
  ];

  useEffect(() => {
    getQualityPlatesGet();
  }, []);

  const getQualityPlatesGet = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/quality/plates/get', {
        params: {
          page_number: +pageData.current.pageNumber,
          search_term: pageData.current.searchTerm,
        }
      });

      if (response.status === 200) {
        setPlateList(response.data.data);
        pageData.current.pageCount = response.data.page_count;
        setApiRes({ ...apiRes, loading: false });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Plates Get',
        loading: false,
      });
    }
  };

  const handlePaginationClick = (e, pageNumber) => {
    pageData.current.pageNumber = pageNumber;
    getQualityPlatesGet();
  };

  const navigateToEdit = plateId => {
    navigate(`/quality/control/plate/${plateId}`);
  };

  const qualityControl = async () => {
    try {
      setApiRes({...apiRes, loading: true});

      const response = await api.post('/api/quality/control', {
        plates_list: JSON.stringify(selectedPlateList),
      });

      if (response.status === 200) {
        getQualityPlatesGet();
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Quality Check',
        loading: false,
      });
    }
  };

  const getUniqueControls = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/quality/unique/controls/get');

      if (response.status === 200) {
        setControlList(response.data);
        setApiRes({
          ...apiRes,
          showAlert: false,
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Plates Get',
        loading: false,
      });
    }
  };

  useEffect(() => {
    if (controlList.length > 0) {
      setGraphIsOpen(true);
      setGraphValues({
        ...graphValues,
        qualityControl: controlList[0].id,
      });
    }
  }, [controlList]);

  useEffect(() => {
    if (graphValues.qualityControl) {
      getGraphData();
      setGraphFilterList([]);
    }
  }, [graphValues.qualityControl]);

  const getGraphData = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/quality/graph/get', {
        params: {
          control_id: graphValues.qualityControl,
        }
      });

      if (response.status === 200) {        
        filterGraphData(response.data, true);
        setApiRes({
          ...apiRes,
          showAlert: false,
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Plates Get',
        loading: false,
      });
    }
  };

  const filterGraphData = (dataList, isApiResponse) => {
    const statusList = ['Pass', 'Warning', 'Fail'];
    const graphColors = {
      'Pass': 'rgba(6, 215, 12, 1)',
      'Warning': 'rgba(243, 201, 9, 1)',
      'Fail': 'rgba(242, 10, 10, 1)',
    };

    for (const row of dataList) {
      let graphList = [];
      for (const status of statusList) {
        const data = generateGraphData(row.control_list, status);
        const graphObj = {
          label: status,
          data: data,
          backgroundColor: graphColors[status]
        };
        graphList.push(graphObj);
      }
      row.graphList = graphList;
    }

    setGraphValues({
      ...graphValues,
      graphList: dataList,
    });

    if (isApiResponse) {
      setGraphTempValues({
        ...graphTempValues,
        graphList: dataList,
      });
    }
  };

  const generateGraphData = (dataList, status) => {
    
    const filterList = dataList.filter(function (el) {
      return el.status.includes(status);
    });
    
    const graphList = [];
    for (const row of filterList) {
      const passObj = {
        x: row.lnp_sample_id, 
        y: row.value, 
        label: row.lnp_sample_replicate,
      };
      graphList.push(passObj);
    }

    return graphList;
  };

  const setDeleteModalOpen = () => {
    return;
  };

  const clearFilterForm = () => {
    setSelectedPlateList([]);
  };

  const getUnVerifiedPlates = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/quality/unverified/plates/get');

      if (response.status === 200) {
        setUnverifiedPlateList(response.data);
        setFilterIsOpen(true);
        setApiRes({
          ...apiRes,
          showAlert: false,
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Plates Get',
        loading: false,
      });
    }
  };

  const handleYAxisChange = (e, metricName, type) => {
    setLatestMetricName(metricName);
    let value = e.target.value;
    let data = [...graphFilterList];

    const filterList = data.filter(obj => obj.metricName === metricName);
    if (filterList.length > 0) {
      data = data.map(obj => 
        obj.metricName === metricName ? {
          ...obj, 
          yMin: type === 'Lower' ? +value : obj.yMin,
          yMax: type === 'Higher' ? +value : obj.yMax,
        } : obj
      );
      setGraphFilterList(data);
    } else {
      const graphTempList = [...graphTempValues.graphList];
      const graphTempFilterList = graphTempList.filter(obj => obj.metric_name === metricName);
      let controlList = [];
      if (graphTempFilterList.length > 0) {
        controlList = graphTempFilterList[0].control_list;
      }
      const filterObj = {
        metricName: metricName,
        yMin: type === 'Lower' ? +value : '',
        yMax: type === 'Higher' ? +value : '',
      };
      setGraphFilterList(prev => [...prev, filterObj]);
    }
  };

  useEffect(() => {
    updateGraphValues();
  }, [graphFilterList]);

  const updateGraphValues = () => {
    const graphList = graphTempValues.graphList.filter(obj => obj.metric_name === latestMetricName);
    const graphfilters = graphFilterList.filter(obj => obj.metricName === latestMetricName);    

    if (graphList.length > 0 && graphfilters.length > 0) {
      let controlList = graphList[0].control_list;
      const graphfilterObj = graphfilters[0];

      if (graphfilterObj.yMin !== '') {
        controlList = controlList.filter(obj => obj.value >= graphfilterObj.yMin);
      }
      
      if (graphfilterObj.yMax !== '') {
        controlList = controlList.filter(obj => obj.value <= graphfilterObj.yMax);
      }
      
      let graphOriginalData = [...graphValues.graphList];
      graphOriginalData = graphOriginalData.map(
        obj => obj.metric_name === latestMetricName ? {
          ...obj, control_list: controlList,
        } : obj
      );

      filterGraphData(graphOriginalData, false);
    }    
  };

  return(
    <AutoLogout>
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      {apiRes.loading && <Loader />}
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <QualityHeader 
          title={'Quality Control'}
          handleClick={navigateToEdit} 
          search={getQualityPlatesGet}
          pageData={pageData}
          getUnVerifiedPlates={getUnVerifiedPlates}
          onGraphClick={getUniqueControls}
          hasSpecialistPermission={permisionsList.includes('Quality-Quality Control Specialist')}
        />

        <CustomTable
          heading={tableHeading} 
          rows={plateList} 
          pageData={pageData}
          idKey={'id'}
          style={{ marginTop: '4px' }}
          navigateToEdit={navigateToEdit}
          setDeleteModalOpen={setDeleteModalOpen}
          isDeleteBtnVisible={false}
          setId={setDeleteModalOpen}
        />

        <QualityFilterModal 
          qualityControl={qualityControl}
          open={filterIsOpen}
          setOpen={setFilterIsOpen}
          clearFilterForm={clearFilterForm}
          unverifiedPlateList={unverifiedPlateList}
          selectedPlateList={selectedPlateList}
          setSelectedPlateList={setSelectedPlateList}
        />

        {plateList.length === 0 && <NoData />}

        {
          (plateList.length !== 0 && pageData.current.pageCount !== 1)
            && 
          <Pagination 
            style={{marginTop: '10px', float: 'right'}} 
            count={pageData.current.pageCount} 
            onChange={handlePaginationClick}
          />
        }

        { 
          graphIsOpen && 
            <QualityMultiGraphModal 
              show={graphIsOpen}
              setShow={setGraphIsOpen}
              controlList={controlList}
              graphValues={graphValues}
              setGraphValues={setGraphValues}
              hoverId={hoverId}
              setHoverId={setHoverId}
              showGraphMetric={showGraphMetric}
              setShowGraphMetric={setShowGraphMetric}
              handleYAxisChange={handleYAxisChange}
              graphFilterList={graphFilterList}
            />
          }

        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </AutoLogout>
  );
};

export default QualityControlPage;