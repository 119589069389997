import PropTypes from 'prop-types';
import { Fragment } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const FormulationForm = ({ values }) => {  
  return(
    <Box sx={{ margin: 1.5 }}>
      <Grid container spacing={1}>
        {values.lipidsList.map((data, index) => 
          <Fragment key={index}>
            <Grid item xs={3} sx={{ marginTop: 1 }}>
              <Typography color={'text.secondary'}>
                <strong>{data.lipid_type_name}: {data.lipid_name}</strong>
              </Typography>
            </Grid>

            <Grid item xs={3} sx={{ marginTop: 1 }}>
              <Typography color={'text.secondary'}>
                <strong>{data.lipid_type_name} Percent: {data.lipid_percent}</strong>
              </Typography>
            </Grid>
          </Fragment>
        )}
      </Grid>

      <Grid container spacing={1} sx={{ marginTop: 1 }}>
        <Grid item xs={6}>
          <Typography color={'text.secondary'}>
            <strong>Lipid Total Molar Concentration: {`${values.lipidTotalMolarConcentration}`}</strong>
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography color={'text.secondary'}>
            <strong>N:P: {`${values.npRatio}`}</strong>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

FormulationForm.propTypes = {
  values: PropTypes.object.isRequired,
};

export default FormulationForm;